<template>
    <div>
        <b-loading v-model="isLoading" :is-full-page="true" />
        <ul v-if="!isLoading">
            <li v-for="key in filteredKeyList" :key="key">
                <b>{{ $t(`restrictions.${key}`) }} : </b>
                <br />
                <ul v-if="key !== 'scrape_sitemap'">
                    <li v-for="item in JSON.parse(restrictions[key])" :key="`${key}.${item}`">
                        {{ item }}
                    </li>
                </ul>
                <span v-else>
                    {{ restrictions[key] }}
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        title: 'Restrictions',
        name: 'GroupSiteRestrictions',
        data() {
            return {
                keyList: ['scrape_sitemap', 'scrape_whitelist', 'scrape_blacklist', 'admin_scrape_blacklist']
            }
        },
        computed: {
            ...mapState('sites', ['restrictions']),
            isLoading() {
                return !this.restrictions
            },
            filteredKeyList() {
                return this.keyList.filter(e => this.restrictions[e])
            }
        },
        methods: {
            ...mapActions('sites', ['getRestrictions'])
        },
        created() {
            this.getRestrictions({ siteId: this.$route.query.id })
        }
    }
</script>
